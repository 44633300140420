
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4 2C3.20435 2 2.44129 2.31607 1.87868 2.87868C1.31607 3.44129 1 4.20435 1 5V18C1 18.7957 1.31607 19.5587 1.87868 20.1213C2.44129 20.6839 3.20435 21 4 21H20C20.7957 21 21.5587 20.6839 22.1213 20.1213C22.6839 19.5587 23 18.7957 23 18V8C23 7.20435 22.6839 6.44129 22.1213 5.87868C21.5587 5.31607 20.7957 5 20 5L12.1 4.99995L12.0902 5.00005C11.923 5.00169 11.758 4.96135 11.6103 4.88274C11.4627 4.80412 11.3372 4.6897 11.2453 4.55001L10.4317 3.34479C10.1589 2.93236 9.7883 2.59373 9.35291 2.35909C8.9157 2.12347 8.42666 2.00008 7.93 2H4ZM3.29289 4.29289C3.48043 4.10536 3.73478 4 4 4H7.93C8.0955 4.00005 8.2584 4.04118 8.40408 4.1197C8.54982 4.19824 8.67373 4.31177 8.76478 4.45004L9.57826 5.65519C9.85389 6.07187 10.2292 6.41316 10.6703 6.64802C11.1115 6.88302 11.6045 7.004 12.1044 7H20C20.2652 7 20.5196 7.10536 20.7071 7.29289C20.8946 7.48043 21 7.73478 21 8V18C21 18.2652 20.8946 18.5196 20.7071 18.7071C20.5196 18.8946 20.2652 19 20 19H4C3.73478 19 3.48043 18.8946 3.29289 18.7071C3.10536 18.5196 3 18.2652 3 18V5C3 4.73478 3.10536 4.48043 3.29289 4.29289ZM15.7071 11.7071C16.0976 11.3166 16.0976 10.6834 15.7071 10.2929C15.3166 9.90237 14.6834 9.90237 14.2929 10.2929L11 13.5858L9.70711 12.2929C9.31658 11.9024 8.68342 11.9024 8.29289 12.2929C7.90237 12.6834 7.90237 13.3166 8.29289 13.7071L10.2929 15.7071C10.6834 16.0976 11.3166 16.0976 11.7071 15.7071L15.7071 11.7071Z"/>
</svg>

  </template>

  <script>
  export default {
    name: 'FolderCheck',
    inheritAttrs: true,
  }
  </script>
